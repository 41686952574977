import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { store } from './app/store/store'
import App from './App'
import reportWebVitals from './reportWebVitals'
import '@fontsource/roboto'
import './index.css'

//import ChakraProvider
import { ChakraProvider } from '@chakra-ui/react'

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
    <Provider store={store}>
        <ChakraProvider>
            <App />
        </ChakraProvider>
    </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
