import React from 'react'

import AllRoutes from './routes/AllRoutes'

function App() {
    return (
        <div>
            <AllRoutes />
        </div>
    )
}

export default App
